import React from 'react'
import PropTypes from 'prop-types'

import Link from './link'
import UnstyledList from './unstyled-list'

const RecipeCard = ({
  title,
  prepTime,
  cookTime,
  totalTime,
  yields,
  ingredients,
  instructions,
  tips
}) => {
  return (
    <div className="recipe-card">
      <div className="recipe-card-head">
        <h2 className="recipe-card-title">
          Recipe Card: {title}
        </h2>
      </div>

      <div className="recipe-card-meta">
        <div className="recipe-card-meta-item">
          <h3 className="recipe-card-meta-title">
            Prep Time
          </h3>
          <p>
            {prepTime} <abbr title="minutes">min</abbr>
          </p>
        </div>

        <div className="recipe-card-meta-item">
          <h3 className="recipe-card-meta-title">
            Cook Time
          </h3>
          <p>
            {cookTime} <abbr title="minutes">min</abbr>
          </p>
        </div>

        <div className="recipe-card-meta-item">
          <h3 className="recipe-card-meta-title">
            Total Time
          </h3>
          <p>
            {totalTime} <abbr title="minutes">min</abbr>
          </p>
        </div>
      </div>

      <div className="recipe-card-section recipe-card-flex">
        <div className="recipe-card-yield">
          <h3 className="recipe-card-subhead">
            Yield
          </h3>
          <p>
            {yields.label}
          </p>
        </div>

        <div className="recipe-card-ingredients">
          <h3 className="recipe-card-subhead">
            Ingredients
          </h3>

          <UnstyledList>
            {ingredients.map(ingredient => (
              <li
                key={[ingredient.amount, ingredient.title].join('-')}
                className="mb-2"
              >
                <strong className="recipe-card-ingredient-amount">
                  {ingredient.amount}
                </strong>
                {' '}

                <Link href={ingredient.link}>
                  {ingredient.title}
                </Link>
              </li>
            ))}
          </UnstyledList>
        </div>
      </div>

      <div className="recipe-card-section">
        <h3 className="recipe-card-subhead">
          Instructions
        </h3>

        <ol className="recipe-card-instructions-list">
          {instructions.map(instruction => (
            <li key={instruction} className="mb-3">
              {instruction}
            </li>
          ))}
        </ol>
      </div>

      {tips ? (
        <div className="recipe-card-section">
          <h3 className="recipe-card-subhead">
            Pro tips
          </h3>
          
          <div dangerouslySetInnerHTML={{ __html: tips }} />
        </div>
      ) : ''}
    </div>
  )
}

RecipeCard.propTypes = {
  title: PropTypes.string.isRequired,
  prepTime: PropTypes.number.isRequired,
  cookTime: PropTypes.number.isRequired,
  totalTime: PropTypes.number.isRequired,
  yields: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  instructions: PropTypes.array.isRequired,
  tips: PropTypes.string,
}

export default RecipeCard
