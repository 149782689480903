import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Tab, Breadcrumb } from 'react-bootstrap'
import { remark } from 'remark'
import recommended from 'remark-preset-lint-recommended'
import remarkHtml from 'remark-html'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Section from '../components/section'
import MetaList from '../components/meta-list'
import StarRating from '../components/star-rating'
import ShareSidebar from '../components/share-sidebar'
import ImageGallery from '../components/image-gallery'
import RecipeCard from '../components/recipe-card'
import Prose from '../components/prose'
import RatingBreakdown from '../components/rating-breakdown'
import Tabs from '../components/tabs'
import NutritionFacts from '../components/nutrition-facts'
import AllergenList from '../components/allergen-list'
import AllergenIcon from '../components/allergen-icon'
import RelatedContent from '../components/related-content'
import SubscribeCta from '../components/subscribe-cta'
import AffiliateDisclaimer from '../components/affiliate-disclaimer'
import PublishDate from '../components/publish-date'

const RecipeTemplate = ({ data, pageContext }) => {
  const recipe = data.markdownRemark
  const meta = recipe.frontmatter
  const { relatedContent } = pageContext
  const slug = pageContext.slug.replace(/\//g, '')
  const canonicalUrl = data.site.siteMetadata.siteUrl + pageContext.slug;

  const socialUrlBase = [
    data.site.siteMetadata.siteUrl,
    '/images/social/',
    slug,
    '/',
    slug,
  ].join('')

  const ogImageUrl = `${socialUrlBase}-open-graph.jpg`
  const twitterImageUrl = `${socialUrlBase}-twitter.jpg`
  const pinterestImageUrl = `${socialUrlBase}-pinterest.jpg`

  const tips = (
    remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(meta.recipe.tips)
      .toString()
      .trim()
  )
  
  return (
    <Layout>
      <Seo
        title={`${meta.title} [Recipe]`}
        description={meta.meta.description}
        canonicalUrl={canonicalUrl}
        meta={[
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'article:section',
            content: 'S’mores Recipes',
          },
          {
            property: 'article:published_time',
            content: meta.publishedOnIso,
          },
          {
            property: 'article:modified_time',
            content: meta.updatedOnIso,
          },
          {
            property: 'article:author',
            content: data.site.siteMetadata.title,
          },
          {
            property: 'og:image',
            content: ogImageUrl,
          },
          {
            property: 'og:image:alt',
            content: meta.title,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:image',
            content: twitterImageUrl,
          },
          {
            name: 'twitter:image:alt',
            content: meta.title,
          },
        ]}
        schema={buildSchema(data, pageContext)}
      />

      <Section className="pb-5">
        <Container>
          <Row>
            <Col md={6} lg={7} className="mb-5 mb-md-0">
              <Breadcrumb>
                <Breadcrumb.Item
                  href="/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item
                  href="/recipes/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Recipes
                </Breadcrumb.Item>
              </Breadcrumb>

              <h1>{meta.title}</h1>

              <MetaList>
                <MetaList.Item>
                  <PublishDate
                    publishedOn={meta.publishedOn}
                    publishedOnIso={meta.publishedOnIso}
                    modifiedOn={meta.modifiedOn}
                    modifiedOnIso={meta.modifiedOnIso}
                  />
                </MetaList.Item>

                <MetaList.Item>
                  Rating:{' '}
                  <StarRating rating={meta.rating.overall} />
                </MetaList.Item>
              </MetaList>

              <ShareSidebar
                title={`Recipe: ${meta.title}`}
                pinterestMedia={pinterestImageUrl}
              />

              <ImageGallery
                images={meta.imageGallery}
                className="prose-margin"
              />

              <p className="lead mb-5">
                {meta.summary}
              </p>

              <div className="mb-5">
                <RecipeCard
                  title={meta.title}
                  prepTime={meta.recipe.prepTime}
                  cookTime={meta.recipe.cookTime}
                  totalTime={meta.recipe.totalTime}
                  yields={meta.recipe.yield}
                  ingredients={meta.recipe.ingredients}
                  instructions={meta.recipe.instructions}
                  tips={tips}
                />
              </div>

              <Prose hasRawHtml={true}>
                {'<h2>Review</h2>' + recipe.html}
              </Prose>

              {meta.hasAffiliateLinks ?
                <div className="mt-5">
                  <AffiliateDisclaimer />
                </div>
              : null}
            </Col>

            <Col md={6} lg={5} className="pl-xl-5">
              <RatingBreakdown ratings={meta.rating} />

              <h2 className="h4">
                The facts
              </h2>
              <Tabs id="facts-tabs">
                <Tab
                  eventKey="nutrition"
                  title="Nutrition"
                  className="tab-pane-facts"
                >
                  <NutritionFacts facts={meta.nutritionFacts} />
                </Tab>

                <Tab
                  eventKey="allergens"
                  title="Allergens"
                  className="tab-pane-allergens"
                >
                  <AllergenList>
                    {meta.allergens.list.map(allergen => (
                      <AllergenList.Item key={allergen.title}>
                        <AllergenIcon icon={allergen.abbr} /> {allergen.title}
                      </AllergenList.Item>
                    ))}
                  </AllergenList>

                  <p>
                    <strong>{meta.allergens.description}</strong>
                  </p>
                </Tab>
              </Tabs>

              <p className="mt-3 small">
                <em>While we make every effort to provide accurate nutritional
                and allergen information, we urge you to double-check the
                ingredients' packaging or manufacturers' websites before
                purchasing and preparing this recipe.</em>
              </p>

              {(() => {
                if (relatedContent && relatedContent.length) {
                  return (
                    <>
                      <h2 className="h4 mt-5">
                        Related
                      </h2>
                      <RelatedContent relatedContent={relatedContent} />
                    </>
                  )
                }
              })()}
            </Col>
          </Row>
        </Container>
      </Section>

      <SubscribeCta>
      <h2 className="mb-3">
          Get s’more recipes like this one in your inbox
        </h2>
      </SubscribeCta>
    </Layout>
  )
}

function buildSchema(data, pageContext) {
  const site = data.site.siteMetadata
  const recipe = data.markdownRemark
  const meta = recipe.frontmatter
  const slug = pageContext.slug.replace(/\//g, '')

  const socialUrlBase = [
    data.site.siteMetadata.siteUrl,
    '/images/social/',
    slug,
    '/',
    slug,
  ].join('')

  const ogImageUrl = `${socialUrlBase}-open-graph.jpg`

  const modifiedOnIso = meta.modifiedOnIso
    ? meta.modifiedOnIso
    : recipe.publishedOnIso

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Recipe",
    "datePublished": meta.publishedOnIso,
    "dateModified": modifiedOnIso,
    "name": meta.title,
    "image": ogImageUrl,
    "description": meta.summary,
    "prepTime": `PT${meta.recipe.prepTime}M`,
    "cookTime": `PT${meta.recipe.cookTime}M`,
    "totalTime": `PT${meta.recipe.totalTime}M`,
    "recipeYield": [
      meta.recipe.yield.servings.toString(),
      meta.recipe.yield.label,
    ],
    "recipeIngredient": [],
    "recipeInstructions": [],
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": meta.rating.overall,
    },
    "author": {
      "@type": "Person",
      "name": "Chris Peters"
    },
    "publisher": {
      "@type": "Organization",
      "name": site.title,
    },
  }

  // Calories info
  const hasCaloriesInfo =
    meta.nutritionFacts &&
    meta.nutritionFacts.keyFacts &&
    meta.nutritionFacts.keyFacts.length

  if (hasCaloriesInfo) {
    schema["nutrition"] = {
      "@type": "NutritionInformation",
      "calories": `${meta.nutritionFacts.keyFacts[0].amount} calories`,
    }
  }

  // Ingredients
  meta.recipe.ingredients.forEach(ingredient => {
    schema["recipeIngredient"].push(
      [ingredient.amount, ingredient.title].join(' ')
    )
  })

  // Instructions
  meta.recipe.instructions.forEach(step => {
    schema["recipeInstructions"].push({
      "@type": "HowToStep",
      "text": step,
    })
  })

  return schema
}

export default RecipeTemplate

export const query = graphql`
  query RecipeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: {
        content_type: { eq: "recipes" }
        slug: { eq: $slug }
      }
    ) {
      html
      frontmatter {
        title
        publishedOn: published_on(formatString: "MMMM D, YYYY")
        publishedOnIso: published_on(formatString: "YYYY-MM-DD")
        modifiedOn: modified_on(formatString: "MMMM D, YYYY")
        modifiedOnIso: modified_on(formatString: "YYYY-MM-DD")
        meta {
          description
        }
        imageGallery: image_gallery {
          thumb: file {
            childImageSharp {
              base: gatsbyImageData(width: 70, height: 70, transformOptions: {
                cropFocus: CENTER
              })
              large: gatsbyImageData(width: 93, height: 93, transformOptions: {
                cropFocus: CENTER
              })
            }
          }
          full: file {
            childImageSharp {
              gatsbyImageData(width: 687, height: 516, transformOptions: {
                cropFocus: CENTER
              })
            }
          }
          file {
            name
            ext
          }
          alt
        }
        recipe {
          prepTime: prep_time
          cookTime: cook_time
          totalTime: total_time
          yield {
            servings
            label
          }
          ingredients {
            amount
            title
            link
          }
          instructions
          tips
        }
        summary
        rating {
          overall
          marshmallow
          chocolate
          graham
        }
        relatedContent: related_content
        nutritionFacts: nutrition_facts {
          servingSize: serving_size
          keyFacts: key_facts {
            title
            amount
          }
          facts {
            title
            amount
            percent
            subFacts: sub_facts {
              title
              amount
              percent
            }
          }
          vitamins {
            title
            amount
            percent
          }
        }
        allergens {
          description
          list {
            title
            abbr
          }
        }
        hasAffiliateLinks: has_affiliate_links
      }
    }
  }
`
