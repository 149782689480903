import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'

export default function Link({ href, children }) {
  if (href?.startsWith('/')) {
    return <GatsbyLink href={href}>{children}</GatsbyLink>
  } else if (href) {
    return <a href={href}>{children}</a>
  }

  return children
}

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any.isRequired,
}
